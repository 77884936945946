import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  IFetchProps,
  Icons,
  LinkButton,
  PageContainer,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { Header, Tabs, Table } from "./components";
import { StyledBreadcrumbs, Content } from "./styled";
import { Role } from "src/types";
import { routes } from "../../routes";
import { ModalConfirmAction } from "@components/ModalConfirmAction";
import {
  useDeleteEmployeeFromRoleMutation,
  useDeleteRoleMutation,
  useRoleEmployeesQuery,
  useRolesQuery,
} from "./queries";
import { ModalOTP, useModalOTPActions } from "@components/ModalOTP";

export const PagePermissions = () => {
  const navigate = useNavigate();
  const topModalRef = useModalOTPActions();
  const searchRef = useRef("");
  const { roleId } = useParams();
  const permissions = usePermissions();
  const { selectedCompany } = useSelectedCompany();
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const [deleteRoleId, setDeleteRoleId] = useState<string>("");
  const [removeEmployeeModalisOpen, setRemoveEmployeeModalisOpen] =
    useState<boolean>(false);
  const [employeeToBeDeletedFromRole, setEmployeeToBeDeletedFromRole] =
    useState<{ id: string; name: string }>({
      id: "",
      name: "",
    });
  const [pageQuery, setPageQuery] = useState<IFetchProps>({
    pageIndex: 1,
    pageSize: 10,
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");

  const { data: rolesData, isLoading: isLoadingRoles } = useRolesQuery({
    companyId: selectedCompany.id,
  });

  const { mutate: deleteRole } = useDeleteRoleMutation({
    companyId: selectedCompany.id,
  });

  const { mutate: deleteEmployeeFromRole } = useDeleteEmployeeFromRoleMutation({
    employeeName: employeeToBeDeletedFromRole.name,
    companyId: selectedCompany.id,
  });

  const { data: roleEmployees, isLoading: isLoadingEmployees } =
    useRoleEmployeesQuery({
      companyId: permissions.isAdmin ? undefined : selectedCompany.id,
      page: pageQuery.pageIndex || 1,
      pageSize: pageQuery.pageSize || 10,
      order: pageQuery.sortBy?.[0]?.desc ? "desc" : "asc",
      sortBy: pageQuery.sortBy?.[0]?.id as "name" | "status",
      roleId: selectedRole?.id || "",
      searchQuery: debouncedSearchTerm,
    });

  useEffect(() => {
    topModalRef.current?.openModalIfNeeded({
      onCancelCallback: () => navigate("/home"),
    });
  }, []);

  useEffect(() => {
    const role = rolesData?.find((p) => p.id === roleId);
    setSelectedRole(role || null);
  }, [roleId, setSelectedRole, rolesData]);

  useEffect(() => {
    const delay = searchRef.current !== searchTerm ? 600 : 0;
    searchRef.current = searchTerm;
    const deBounceTimeout = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);
    return () => clearTimeout(deBounceTimeout);
  }, [searchTerm]);

  const onOrderChanged = (input: IFetchProps) => {
    if (input.sortBy) {
      setPageQuery({ ...pageQuery, sortBy: input.sortBy });
    }
  };

  const onPaginationChanged = (input: IFetchProps) => {
    if (input.pageIndex !== undefined && input.pageSize !== undefined) {
      setPageQuery({ pageSize: input.pageSize, pageIndex: input.pageIndex });
    }
  };

  const onRemoveEmployeeFromRole = (data: { id: string; name: string }) => {
    setEmployeeToBeDeletedFromRole({
      id: data.id,
      name: data.name,
    });
    setRemoveEmployeeModalisOpen(true);
  };

  const isLoading = isLoadingRoles || isLoadingEmployees;

  return (
    <PageContainer>
      <StyledBreadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={[
          <LinkButton variant="secondary" onClick={() => navigate("/settings")}>
            Gerenciar empresa
          </LinkButton>,
          <LinkButton variant="secondary">Acessos e permissões</LinkButton>,
        ]}
      />
      <Header />
      <Content>
        <Tabs
          onDeleteRole={(roleId) => {
            setDeleteRoleId(roleId);
          }}
          roles={rolesData || []}
          selectedRole={selectedRole}
          setSelectedRole={(role: Role) => {
            setSearchTerm("");
            navigate(
              routes.pageRoles +
                `/${role.id}` +
                `?company=${selectedCompany.id}`
            );
          }}
        />
        <Table
          onPaginationChanged={onPaginationChanged}
          onOrderChanged={onOrderChanged}
          searchValue={searchTerm}
          onSearch={setSearchTerm}
          employees={roleEmployees?.employees || []}
          selectedRole={selectedRole}
          loading={isLoading}
          hasRoles={Boolean(rolesData?.length)}
          onRemoveEmployeeFromRole={onRemoveEmployeeFromRole}
        />
      </Content>
      <ModalConfirmAction
        isOpen={Boolean(deleteRoleId)}
        loading={false}
        title="Tem certeza que deseja excluir este perfil de permissão?"
        description="As pessoas deste perfil de permissão serão automaticamente atribuídas ao perfil de permissão “Permissões básicas”."
        confirmWord="EXCLUIR"
        submitText={
          <>
            <span>Confirmar exclusão</span>
            <Icons name="IconTrash" size={30} fill="transparent" />
          </>
        }
        onClose={() => setDeleteRoleId("")}
        onSubmit={() => {
          deleteRole({ roleId: deleteRoleId });
          navigate(routes.pageRoles + `?company=${selectedCompany.id}`, {
            replace: true,
          });
          setDeleteRoleId("");
        }}
      />
      <ModalConfirmAction
        isOpen={removeEmployeeModalisOpen}
        loading={false}
        title="Tem certeza que deseja desvincular esta pessoa deste perfil de permissão?"
        description={
          <>
            Ela não terá mais permissão para gerenciar informações e
            configurações da <b>empresa</b>.
          </>
        }
        submitText={
          <>
            <span>Desvincular pessoa</span>
          </>
        }
        onClose={() => setRemoveEmployeeModalisOpen(false)}
        onSubmit={() => {
          deleteEmployeeFromRole({
            roleId: roleId || "",
            employeeId: employeeToBeDeletedFromRole.id,
          });
          setEmployeeToBeDeletedFromRole({ id: "", name: "" });
          setRemoveEmployeeModalisOpen(false);
        }}
        submitButtonStyle={{
          margin: "auto 0",
          padding: "16px 24px",
          background: "var(--color-feedback-error-90)",
        }}
      />
      <ModalOTP
        subtitle="Para a segurança dos dados sensíveis da sua empresa, você precisa confirmar sua identidade para acessar a página de Acessos e Permissões."
        ref={topModalRef}
      />
    </PageContainer>
  );
};
